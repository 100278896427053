<template>
  <form @submit.prevent="onSubmit()">

    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <Btn class="back-btn" round grow icon="arrow-left" :to="{
          name: 'suppliers'
        }" />
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <div class="grid">
            <div class="col-tmp">
              <Input id="nom" label="Nom" required />
            </div>
            <div class="grid">
              <Input v-if="helperService.userHasProfil('super-administrateur')" id="uid" label="uid" required />
            </div>
            <div class="col-tmp">
              <SelectExtended id="thematiques" label="Thematique" option-key="uid" option-value="designation"
                api-endpoint="dictionnaire/thematique" multiple required />
            </div>
          </div>

        </Container>
      </Section>
    </div>

    <div class="view-footer">
      <div class="view-actions view-actions--left">
        <Btn text="Annuler" :to="{
          name: 'suppliers'
        }" />
        <Btn v-if="action === 'edit' && helperService.userHasPermission('fournisseur_remove')" text="Supprimer" hollow
          @click="modalActive = true" />
        <Btn v-if="helperService.userHasPermission('fournisseur_add')
          || helperService.userHasPermission('fournisseur_edit')" btnType="submit"
          :text="action === 'edit' ? 'Modifier' : 'Sauvegarder'" color="primary" />
      </div>
    </div>

  </form>

  <!-- Modals modalDelete-->
  <Modal v-if="$route.params.id" title="Supprimer le fournisseur ?" :active="modalActive"
    @modal-close="modalActive = false">
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer le fournisseur <b>{{ supplier.nom }}</b>&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.deleteItem = false" />
      <Btn text="Supprimer" @click="deleteSupplier()" icon="trash-alt" color="primary" />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'SupplierAddEditView',

  components: {
    Modal,
    SelectExtended,
    Btn,
    Input,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    // Define a validation schema
    let validationSchema = {}

    if (this.helperService.userHasProfil('super-administrateur')) {
      validationSchema = this.yup.object().shape({
        nom: this.yup.string().required(),
        uid: this.yup.string().required(),
        thematiques: this.yup.array().min(1),
      })
    } else {
      validationSchema = this.yup.object().shape({
        nom: this.yup.string().required(),
        thematiques: this.yup.array().min(1),
      })
    }

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      if (this.action === 'add') {
        this.createSupplier(values)
      } else {
        this.updateSupplier(values)
      }
    })

    return {
      supplier: [],
      supplierId: this.$route.params.id ?? null,
      action: this.$route.params.id ? 'edit' : 'add',
      data: [],
      modalActive: false,
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  mounted() {
    this.getSupplier()
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  methods: {
    createSupplier(values) {
      if (values.uid === null) {
      // eslint-disable-next-line no-param-reassign
        values.uid = this.helperService.generateUid(values.nom)
      }

      // eslint-disable-next-line no-param-reassign
      values.thematiques = values.thematiques.map((thematique) => ({
        uid: thematique.key,
      }))

      this.fetchService.post('fournisseur', values).then(() => {
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le fournisseur a bien été crée.',
        })

        this.$router.push({ name: 'suppliers' })
      },
      (responseError) => {
        this.emitter.emit('alert', {
          type: 'error',
          content: responseError.data,
        })
      })
    },

    updateSupplier(values) {
      if (values.uid === null) {
      // eslint-disable-next-line no-param-reassign
        values.uid = this.helperService.generateUid(values.nom)
      }

      // eslint-disable-next-line no-param-reassign
      values.thematiques = values.thematiques.map((thematique) => ({
        uid: thematique.key,
      }))

      this.fetchService.put(`fournisseur/${this.supplierId}`, values).then(() => {
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le fournisseur a bien été modifié.',
        })

        this.$router.push({ name: 'suppliers' })
      },
      (responseError) => {
        this.formService.handleApiError(responseError, 'Impossible de créer l\'utilisateur')
        this.emitter.emit('alert', {
          type: 'error',
          content: responseError.data,
        })
      })
    },

    deleteSupplier() {
      this.fetchService.delete(`fournisseur/${this.supplierId}`).then(() => {
        this.modalActive = false

        this.emitter.emit('alert', {
          type: 'success',
          content: 'Le fournisseur a bien été supprimé.',
        })

        this.$router.push({ name: 'suppliers' })
      })
    },

    getSupplier() {
      if (this.action === 'edit') {
        this.emitter.emit('open-loader')
        this.fetchService.get(`fournisseur/${this.supplierId}`).then((response) => {
          this.supplier = response.data

          this.schema = this.formService.populateShema(this.supplier)

          if (this.supplier.thematiques && this.supplier.thematiques.length > 0) {
            this.schema.thematiques = this.supplier.thematiques.map((thematique) => ({
              key: thematique.uid,
              value: thematique.designation,
            }))
          }

          this.formService.setFormValues(this.schema)

          this.emitter.emit('close-loader')
        })
      }
    },
  },
}
</script>

<style scoped></style>
